import React, { useState } from "react";
import { AiOutlineFileAdd, AiOutlineEnvironment } from "react-icons/ai";

const PharmacyForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    age: "",
    gender: "",
    description: "",
    prescription: null,
  });

  const phoneNumber = "7702068334"; 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, prescription: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, contact, address, age, gender, description, prescription } = formData;

    const message = `Hello, I am submitting my order details:\n
    - Name: ${name}\n
    - Contact: ${contact}\n
    - Age: ${age}\n
    - Gender: ${gender}\n
    - Address: ${address}\n
    - Description: ${description}\n
    - Prescription: ${prescription ? prescription.name : "Not uploaded"}`;

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL, "_blank");

    alert("Form data has been sent to WhatsApp!");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
      <form
        className="relative bg-white text-gray-600 p-6 md:p-8 rounded-lg shadow-md max-w-lg mx-4 sm:mx-auto w-full transition-all hover:scale-105"
      >
        <button
          type="button"
          className="absolute top-4 right-4 text-black text-xl font-bold hover:text-red-500 transition"
          onClick={onClose}
          aria-label="Close"
        >
          ×
        </button>

        <h2 className="text-2xl text-teal-600 font-bold mb-6 text-center">
          Pharmacy Form
        </h2>

        <div className="flex flex-col gap-2 mb-4">
          <button
            type="button"
            className="flex items-center gap-2 hover:bg-gray-300 bg-gray-200 px-3 py-2 rounded-md text-gray-700 w-full sm:w-auto"
            onClick={() => document.getElementById("prescription").click()}
          >
            <AiOutlineFileAdd /> Upload Prescription
          </button>
          <input
            type="file"
            id="prescription"
            name="prescription"
            className="hidden"
            onChange={handleFileChange}
          />
          {formData.prescription && (
            <p className="text-sm text-gray-600">
              Uploaded File: <span className="font-medium">{formData.prescription.name}</span>
            </p>
          )}
        </div>

        <input
          type="text"
          name="name"
          placeholder="Enter your name"
          className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="contact"
          placeholder="Enter your contact number"
          className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
          value={formData.contact}
          onChange={handleInputChange}
          required
        />
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <input
            type="number"
            name="age"
            placeholder="Enter your age"
            className="flex-1 border border-gray-300 rounded-lg px-4 py-2"
            value={formData.age}
            onChange={handleInputChange}
            required
          />
          <select
            name="gender"
            className="flex-1 border border-gray-300 rounded-lg px-4 py-2"
            value={formData.gender}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="relative mb-4 flex items-center">
          <input
            type="text"
            name="address"
            placeholder="Enter your address"
            className="flex-1 border border-gray-300 rounded-lg px-4 py-2"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
          <AiOutlineEnvironment className="text-red-700 ml-2" />
        </div>
        <textarea
          name="description"
          placeholder="Enter additional details"
          className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
          rows="4"
          value={formData.description}
          onChange={handleInputChange}
        ></textarea>

        <div className="flex justify-center items-center gap-6 mt-4">
          <button
            type="button"
            className="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded-lg w-full sm:w-auto"
            onClick={() =>
              setFormData({
                name: "",
                contact: "",
                address: "",
                age: "",
                gender: "",
                description: "",
                prescription: null,
              })
            }
          >
            Reset
          </button>
          <button
            type="submit"
            className="bg-teal-600 hover:bg-teal-500 text-white px-4 py-2 rounded-lg w-full sm:w-auto"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PharmacyForm;
